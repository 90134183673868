import React, { useState, createContext, useCallback } from 'react';
import axios from 'axios';

import { getUrlApi } from '../servicos/apiUrl';
import { getToken, getSchema } from '../servicos/autenticacao';
import { requisicao } from '../servicos/api';

export const ContextoEleitoral = createContext();

let ultimaCidadeLatitude, ultimoCpf;

const ContextoEleitoralProvider = props => {

  const [ carregando, setCarregando ] = useState(true);
  const [ marcadoresComites, setMarcadoresComites ] = useState([]);
  const [ marcadoresLocaisDeVotacao, setMarcadoresLocaisDeVotacao ] = useState([]);
  const [ dadosCandidato, setDadosCandidato ] = useState({});
  const [ deputado, setDeputado ] = useState(false);
  const [ schemaGeral, setSchemaGeral ] = useState(false);
  const [ anosEleitorais, setAnosEleitorais ] = useState([]);
  const [ anoEleitoralSelecionado, setAnoEleitoralSelecionado ] = useState(!deputado && !schemaGeral ? 2020 : null);

  const token = getToken();
  const schemaLogado = getSchema();

  const getDadosCandidato = useCallback(async () => {
    try {
      const resposta = await requisicao('api-pessoas', '/meu-candidato', 'GET');
      const candidato = resposta.data[0];

      if (candidato) {
        resposta.data[0].cpf_cnpj = resposta.data[0].cpf_cnpj.replace(/[^0-9]/g, '');
        setDeputado(candidato?.cargo.toLowerCase().includes('deputado'));
        setDadosCandidato({
          cidade: candidato?.cidade,
          idCidade: candidato?.idCidade,
          latitude: candidato?.latitude,
          longitude: candidato?.longitude,
          nome: candidato?.nome,
          cpf: candidato?.cpf_cnpj,
          cargo: candidato?.cargo
        });
      } else {
        setSchemaGeral(true);
        setCarregando(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [setDadosCandidato]);

  const getMarcadoresComites = useCallback(async (latitude, longitude, raioDeVisualizacao = '') => {
    if(ultimaCidadeLatitude === latitude || deputado || schemaGeral) return;

    try {
      setCarregando(true);
      const url = `/articulador/pessoa/localizacao?geo-lat=${latitude}&geo-long=${longitude}&raio=100&comite-familiar=true&cores=&perfis=&grupos=`;
      const resposta = await requisicao('api-java', url, 'GET');

      setMarcadoresComites(resposta.data.output.length && resposta.data.output.map(marcador => (
        {
          bairro: marcador.bairro,
          nome: marcador.nome,
          latitude: marcador.latitude,
          longitude: marcador.longitude,
          idcomite: marcador.idcomite
        }
      )));
    } catch (e) {
      console.error(e);
    } finally {
      ultimaCidadeLatitude = latitude;
    }
  }, [dadosCandidato]);

  const getMarcadoresLocaisDeVotacao = useCallback(async cpf => {
    if(ultimoCpf === cpf) {
      setCarregando(false);
      return;
    }
    try {
      ultimoCpf = cpf;
      const url = `${getUrlApi('api-eleitoral')}/votacao/locais/${anoEleitoralSelecionado}/?cpf=${cpf}`;

      const resposta = await axios.create().get(url, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setMarcadoresLocaisDeVotacao(resposta.data);
    } catch (e) {
      console.error(e);
    } finally {
      setCarregando(false);
    }
  }, [anoEleitoralSelecionado]);

  const getAnosEleitorais = useCallback(async () => {
    try {
      const resposta = await requisicao('api-eleitoral', `/votacao/anos`, 'GET');
      setAnosEleitorais(resposta.data.map(res => {
        return res.ano;
      }));
    } catch (e) {
      console.error(e);
    }
  }, [setAnosEleitorais]);

  // InfoWindow
  const [ exibindoInfoWindow, setExibindoInfoWindow ] = useState(false);
  const [ localSelecionado, setLocalSelecionado ] = useState({});
  const [ marcadorAtivo, setMarcadorAtivo ] = useState({});

  const onMarkerClick = useCallback((prop, marker) => {
    setLocalSelecionado(prop);
    setMarcadorAtivo(marker);
    setExibindoInfoWindow(true);
  }, []);

  const onInfoWindowClose = useCallback(() => {
    setMarcadorAtivo(null);
    setExibindoInfoWindow(false);
  }, []);


  return (
    <ContextoEleitoral.Provider value={{
      anoEleitoralSelecionado,
      anosEleitorais,
      carregando,
      dadosCandidato,
      deputado,
      exibindoInfoWindow,
      localSelecionado,
      marcadorAtivo,
      marcadoresComites,
      marcadoresLocaisDeVotacao,
      schemaGeral,
      getAnosEleitorais,
      getDadosCandidato,
      getMarcadoresComites,
      getMarcadoresLocaisDeVotacao,
      onInfoWindowClose,
      onMarkerClick,
      setAnoEleitoralSelecionado,
      setAnosEleitorais,
      setCarregando,
      setDadosCandidato,
      setMarcadoresComites,
      setMarcadoresLocaisDeVotacao
    }}>
      {props.children}
    </ContextoEleitoral.Provider>
  );
};

export default ContextoEleitoralProvider;
