import styled from 'styled-components';

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography 
} from '@material-ui/core';

export const Container = styled.div`
  ${props => props.lido && `
    opacity: 0.5;
  `}
  background-color: #fff;
  padding: 20px;
  border-top: 2px solid lightgray;

  &:first-child {
    margin-top: 50px;
  }
`;

export const Cabecalho = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

export const NovaNotificacao = styled.div`
  min-width: 0.6rem;
  height: 0.6rem;
  display: inline-block;

  margin-right: var(--espacamento-xs);

  background: red;
  border-radius: 50%;
`;

export const Titulo = styled.h2`
  font-size: var(--text);
  font-weight: var(--font-bolder);
  display: inline;
  opacity: 0.8;
`;

export const TempoDeCriacao = styled.span`
  font-size: var(--small);
  color: var(--app-text-50-color);
  white-space: nowrap;
  margin-left: var(--espacamento-xs);
`;

export const Conteudo = styled.p`
  margin: var(--espacamento-s) 0;
  color: var(--app-text-color);
  font-size: var(--text);
`;

export const Rodape = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Botao = styled(Button)`
  && {
    padding: 0;

    font-size: var(--small);
    font-weight: var(--font-bold);
    color: var(--button-font-color);

    span {
      font-family: var(--fonte-2);
      line-height: normal;
    }
  }
`;

export const CheckBoxContainer = styled(FormControlLabel)`
  && span {
    font-family: var(--fonte-1);
    font-size: var(--small);
  }
`;

export const CheckBox = styled(Checkbox)`
  && {
    margin-right: var(--espacamento-xxs);
    padding: 0;

    &.Mui-checked {
      ${props => props.checked && `
        color: #000;
      `}

      font-size: var(--text);
    }
  }
`;

export const SemNotificacoes = styled(Typography)`
  && {
    color: var(--app-text-50-color);
    display: flex;
    align-items: center;
    margin: auto;
    font-size: var(--subtitle);
  }
`;

export const Carregando = styled(CircularProgress)`
  && {
    width: 20px!important;
    height: 20px!important;
    margin-right: 8px;
    color: var(--app-primary-color);
  }
`;