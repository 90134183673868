import styled from 'styled-components';
import {
  Radio,
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';

export const FiltroContainer = styled.div`
  margin: var(--espacamento-m) 0;
`;

export const Titulo = styled.p`
  font-size: var(--text);
  font-weight: var(--font-bold);
`;

export const ConteudoLista = styled(FormGroup)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const ConteudoItem = styled(FormControlLabel)`
  && {
    padding: 12px;

    font-size: var(--text);
    background: ${props => props.checked ? 'var(--filtro-selecionado)' : 'var(--app-background-10-color)'};

    margin: var(--espacamento-xxs) 0;

    ${props => props.eleito && `order: -1;`}

    width: 100%;

    > span:nth-child(2) {
      width: 100%;
    }
  }
`;

export const RadioButton = styled(Radio)`
  && {
    display: none;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    margin-right: var(--espacamento-xs);
  }

  span {
    &:first-child {
      margin-right: var(--espacamento-xs);

      font-size: 0.75rem;
      font-weight: var(--font-bolder);

      text-transform: uppercase;
      color: #5052f7;
    }

    &:last-child {
      margin-left: auto;

      color: var(--app-text-50-color)
    }
  }
`;

export const ListaVazia = styled.div`
  padding: 12px;
  font-size: var(--text);
  background: var(--app-background-10-color);
  margin: var(--espacamento-xxs) 0;
`;