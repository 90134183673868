import React, { memo } from 'react';

import { Container } from '../../componentes';
import { Icone, Titulo, Texto } from './styles';

const NaoAutorizado = () => {
  return (
    <Container verticalCenter horizontalCenter bgGray>
      <Icone />
      <Titulo variant="h6">
          Sessão não autorizada.
      </Titulo>
      <Texto>
        Verifique se você possui autorização para utilizar esse módulo
        e recarregue a página novamente.
      </Texto>
    </Container>
  );
};

export default memo(NaoAutorizado);