import styled from 'styled-components';

import { Button } from '@material-ui/core';

export const Container = styled.section`
  display: ${ props => props.hidden ? 'none' : 'flex' };
  flex: 1;
  flex-direction: ${ props => props.row ? 'row' : 'column' };
  flex-wrap: wrap;
  padding: ${ props => props.padding ? '5vmin' : 'initial' };
  margin: 0 auto;
  min-height: 100vh;
  font-size: 1rem;

  ${props => props.wrapped && `max-width: 600px`};
  /* Background color */
  background-color: ${props => props.bgGray && `var(--app-background-10-color)`};

  /* TODO: converter em funcao */
  /* max-width: 500px; */

  /* Estica conteudo */
  ${props => props.base && `> * { flex: 1; }`}

  /* Centraliza Vertical */
  ${props => props.verticalCenter && `justify-content: center;`}

  /* Centraliza Horizontal */
  ${props => props.horizontalCenter && `align-items: center;`}

  /* Normalize */
  Button {
    font-size: inherit;
  }
  .MuiTextField-root {
    margin-bottom: 10px;
  }
`;

export const Botao = styled(Button)`
  && {
    padding: 0;

    font-size: var(--small);
    font-weight: var(--font-bold);
    color: var(--button-font-color);

    ${props => props.secondary && `
      color: var(--app-primary-color);

      &:hover {
        opacity:
      }
    `}

    ${props => props.primary && `
      background-color: var(--app-primary-color);
      color: var(--app-background-white-color);
      border: 1px solid var(--app-primary-color);;

      &:hover {
        background-color: var(--app-background-white-color);
        color: var(--app-primary-color);

        transition: 300ms linear;
      }
    `}

    ${props => props.disabled && `
      background-color: transparent;
      border-color: transparent;
    `}

    span {
      font-family: var(--fonte-2);
      line-height: normal;
    }
  }
`;