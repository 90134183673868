export const mapStyle = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }]
  }
];

export const gradient = [
  'rgba(255,236,0, 0)',
  'rgba(255,236,0, 1)',
  'rgba(255,226,0, 1)',
  'rgba(255,216,0, 1)',
  'rgba(255,206,0, 1)',
  'rgba(255,196,0, 1)',
  'rgba(255,186,0, 1)',
  'rgba(255,177,0, 1)',
  'rgba(255,167,0, 1)',
  'rgba(255,157,0, 1)',
  'rgba(255,147,0, 1)',
  'rgba(255,137,0, 1)',
  'rgba(255,128,0, 1)',
  'rgba(255,116,0, 1)',
  'rgba(255,104,0, 1)',
  'rgba(255,93,0, 1)',
  'rgba(255,81,0, 1)',
  'rgba(255,69,0, 1)',
  'rgba(255,58,0, 1)',
  'rgba(255,46,0, 1)',
  'rgba(255,34,0, 1)',
  'rgba(255,23,0, 1)',
  'rgba(255,11,0, 1)',
  'rgba(255,0,0, 1)',
  'rgba(243,0,0, 1)',
  'rgba(232,0,0, 1)',
  'rgba(220,0,0, 1)',
  'rgba(209,0,0, 1)',
  'rgba(198,0,0, 1)',
  'rgba(186,0,0, 1)',
  'rgba(175,0,0, 1)',
  'rgba(164,0,0, 1)',
  'rgba(152,0,0, 1)',
  'rgba(141,0,0, 1)',
  'rgba(130,0,0, 1)',
];