import React, { memo, useEffect, useContext, useState } from 'react';
import _ from 'lodash';

import FilterListIcon from '@material-ui/icons/FilterList';

import { Filtro, GoogleMaps, TopBar } from '../../componentes';

import { ContextoEleitoral } from '../../contextos/ContextoEleitoral';
import ContextoFiltroProvider from '../../contextos/ContextoFiltro';

import InfoCandidato from './InfoCandidato';

const Eleitoral = () => {
  const { getDadosCandidato, getAnosEleitorais, dadosCandidato, anoEleitoralSelecionado, carregando } = useContext(ContextoEleitoral);
  const [ abrirFiltro, setAbrirFiltro ] = useState(false);
  const [ candidatoFiltrado, setCandidatoFiltrado ] = useState({});

  useEffect(() => {
    getDadosCandidato();
  }, [getDadosCandidato]);
  
  useEffect(() => {
    getAnosEleitorais();
  }, [getAnosEleitorais]);

  useEffect(() => {
    if(dadosCandidato) {
      setCandidatoFiltrado({
        ...dadosCandidato,
        ...candidatoFiltrado
      });
    }
  }, [dadosCandidato]);

  return (
    <>
      <TopBar
        position='relative'
        titulo='Eleitoral'
        subCabecalho={
          !_.isEmpty(dadosCandidato) && <InfoCandidato anoEleicao={anoEleitoralSelecionado} candidato={candidatoFiltrado} carregando={carregando}/>
        }
        filtro={!_.isEmpty(dadosCandidato)}
        carregando={carregando}
        iconeDireita={<FilterListIcon />}
        acaoIconeDireita={() => {
          setAbrirFiltro(!abrirFiltro);
        }}
      />

      <ContextoFiltroProvider>
        <Filtro abrir={abrirFiltro} setAbrirFiltro={setAbrirFiltro} setCandidatoFiltrado={setCandidatoFiltrado} />
      </ContextoFiltroProvider>

      <GoogleMaps setAbrirFiltro={setAbrirFiltro} />
    </>
  );
};

export default memo(Eleitoral);
