import React, { useState, createContext, useCallback, useRef } from 'react';

import { requisicao } from '../servicos/api';

export const Context = createContext();

let notificacoes = [];

const ContextProvider = props => {

  const [ carregando, setCarregando ] = useState(true);
  const [ pagina, setPagina ] = useState(1);
  const [ carregandoNotificacoes, setCarregandoNotificacoes ] = useState(Boolean);
  const [ atualizandoNotificacoes, setAtualizandoNotificacoes ] = useState(Boolean);
  const [ temMaisRequisicoes, setTemMaisRequisicoes ] = useState(true);
  const [ erro, setErro ] = useState(false);

  const getNotificacoes = useCallback(async () => {
    try {
      const resposta = await requisicao('api-notificacoes', `/notificacoes?pagina=${pagina}`, 'GET');

      if (resposta.data.length < 15) setTemMaisRequisicoes(false);

      if (resposta.status === 200 && resposta.data.length) {
        notificacoes.push(...resposta.data);
      }
    } catch(erro) {
      console.error(erro);
    } finally {
      setCarregando(false);
      setCarregandoNotificacoes(false);
    }
  }, [pagina]);

  const recarregaNotificacoes = useCallback(() => {
    setPagina(1);
    setCarregando(true);
    notificacoes = [];
    getNotificacoes();
  }, [setPagina, setCarregandoNotificacoes, getNotificacoes]);

  const alteraNotificacaoLida = useCallback(async (notificacao) => {
    let corpo = {
      lido: notificacao.lido,
    };

    try {
      setAtualizandoNotificacoes(true);
      await requisicao('api-notificacoes', `/notificacoes/${notificacao.idnotificacao}`, 'PUT', corpo);
    } catch (e) {
      console.error(e);
      setErro(true);
    } finally {
      notificacoes = notificacoes.map(item => {
        if(item.idnotificacao === notificacao.idnotificacao) {
          return {
            ...item,
            lido: notificacao.lido
          };
        } else {
          return item;
        }
      });
      setAtualizandoNotificacoes(false);
    }
  }, []);

  const observador = useRef();

  const referenciaDaUltimaNotificacao = useCallback(node => {
    if (carregandoNotificacoes) return;

    if (observador.current) observador.current.disconnect();

    observador.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && temMaisRequisicoes) {
        setCarregandoNotificacoes(true);
        setPagina(prevPagina => prevPagina +1);
      }
    });

    if (node) observador.current.observe(node);
  }, [carregandoNotificacoes]);

  return (
    <Context.Provider value={{
      getNotificacoes,
      recarregaNotificacoes,
      alteraNotificacaoLida,
      setCarregandoNotificacoes,
      setPagina,
      setErro,
      referenciaDaUltimaNotificacao,
      notificacoes,
      carregando,
      carregandoNotificacoes,
      erro,
      atualizandoNotificacoes,
    }}>
      {props.children}
    </Context.Provider>
  );
};

export default ContextProvider;
