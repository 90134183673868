import React, { memo, useState, useEffect, useContext, useCallback } from 'react';
import styled from 'styled-components';

import { Context } from '../../contextos/AppContext';

import { Snackbar, IconButton } from '@material-ui/core/';
import { Close as CloseIcon } from '@material-ui/icons';

export const Mensagem = styled(Snackbar)`
  && > div {
    display: flex;
    justify-content: center;

    background-color: var(--app-error-color);
  }
`;

const MensagemDeErro = () => {
  const { erro, setErro } = useContext(Context);
  const [state, setState] = useState({
    vertical: 'bottom',
    horizontal: 'center',
  });
  const { vertical, horizontal } = state;

  useEffect(() => {
    const exibicao = setTimeout(() => {
      fechaMensagem();
    }, 2500);

    return () => {
      clearTimeout(exibicao);
    };
  }, []);

  const fechaMensagem = useCallback(() => {
    setErro(false);
  }, [setErro]);

  return (
    <Mensagem
      anchorOrigin={{ vertical, horizontal }}
      open={erro}
      onClose={fechaMensagem}
      key={vertical + horizontal}
      message="Oops... houve um erro, por favor recarregue a página."
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={fechaMensagem}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

export default memo(MensagemDeErro);
