import React, { useCallback, useEffect, useState, useContext, memo } from 'react';
import _ from 'lodash';

import { MuiDialog } from '../';
import Cidade from './Cidade';
import Candidato from './Candidato';
import AnoEleicao from './AnoEleicao';

import CloseIcon from '@material-ui/icons/Close';

import { ContextoEleitoral } from '../../contextos/ContextoEleitoral';
import { ContextoFiltro } from '../../contextos/ContextoFiltro';


const Filtro = ({ abrir, setAbrirFiltro, setCandidatoFiltrado }) => {
  const { dadosCandidato, deputado, schemaGeral, setDadosCandidato, anoEleitoralSelecionado } = useContext(ContextoEleitoral);
  const { getCandidatos, candidatoAtual, setCandidatoAtual } = useContext(ContextoFiltro);
  const [ posicaoEtapaAtual, setPosicaoEtapaAtual ] = useState(0);
  const [ desabilitaBotao, setDesabilitaBotao ] = useState(false);

  useEffect(() => {
    setCandidatoAtual(dadosCandidato);

    if (deputado || schemaGeral) return;
    else getCandidatos(dadosCandidato.idCidade, anoEleitoralSelecionado);

  }, [dadosCandidato]);

  
  useEffect(() => {
    setDesabilitaBotao(true);
  }, [deputado, schemaGeral]);

  let etapas = [
    {
      componente: <AnoEleicao 
        candidatoAtual={candidatoAtual} 
        setCandidatoAtual={setCandidatoAtual} 
        setDesabilitaBotao={setDesabilitaBotao} 
      />,
      botaoPrimario: 'Avançar',
    },
    {
      componente: <Cidade 
        candidatoAtual={candidatoAtual} 
        setCandidatoAtual={setCandidatoAtual} 
        setDesabilitaBotao={setDesabilitaBotao} 
        deputado={deputado} 
        schemaGeral={schemaGeral}
      />,
      botaoPrimario: 'Avançar',
      botaoSecundario: 'Voltar'
    },
    {
      componente: <Candidato etapa={posicaoEtapaAtual} candidatoAtual={candidatoAtual} setCandidatoAtual={setCandidatoAtual} setDesabilitaBotao={setDesabilitaBotao}/>,
      botaoPrimario: 'Filtrar',
      botaoSecundario: 'Voltar'
    },
  ];

  const acaoBotao = useCallback((proximaEtapa, etapaAnterior) => {
    if (proximaEtapa) {
      setCandidatoAtual(candidatoAtual);
      setPosicaoEtapaAtual(prevPosicao => prevPosicao + 1);
    }
    else if (etapaAnterior) {
      setCandidatoAtual(candidatoAtual);
      setPosicaoEtapaAtual(prevPosicao => prevPosicao - 1);
    }
    else {
      setCandidatoFiltrado({
        ...dadosCandidato,
        ...candidatoAtual,
        ano: anoEleitoralSelecionado
      });
      setDadosCandidato({
        ...dadosCandidato,
        ...candidatoAtual
      });
      setAbrirFiltro(false);
      setPosicaoEtapaAtual(0);
    }

  }, [posicaoEtapaAtual, candidatoAtual, dadosCandidato]);

  const titulo = <>
    Filtro
    <span>{posicaoEtapaAtual + 1} de {etapas.length}</span>

    <CloseIcon
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setAbrirFiltro(false);
        setDesabilitaBotao(false);
        if(deputado || schemaGeral)
          setDesabilitaBotao(true);
        
        setPosicaoEtapaAtual(0);
      }}
    />
  </>;

  return (
    <MuiDialog
      titulo={titulo}
      filtro="true"
      conteudo={etapas[posicaoEtapaAtual].componente}
      botaoPrimario={etapas[posicaoEtapaAtual].botaoPrimario}
      acaoBotaoPrimario={() => acaoBotao(etapas[posicaoEtapaAtual].botaoPrimario === 'Avançar', undefined)}
      abrir={abrir}
      botaoSecundario={etapas[posicaoEtapaAtual]?.botaoSecundario}
      acaoBotaoSecundario={() => acaoBotao(undefined, etapas[posicaoEtapaAtual]?.botaoSecundario === 'Voltar')}
      desabilitar={desabilitaBotao}
    />
  );
};

export default memo(Filtro);