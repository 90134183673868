import styled from 'styled-components';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';

export const Container = styled(Dialog)`
  && {
    .MuiPaper-root {
      border-radius: 0;
      padding: var(--espacamento-m);
      padding-bottom: ${props => props.filtro && '5px'};
      width: 100vmin;
      background-color: ${props => props.filtro ? '#ebebeb' : '#fff'};
    }
  }
`;
export const Titulo = styled(DialogTitle)`
  && {
    padding: 0;

    h2 {
      display: flex;
      align-items: center;

      font-size: var(--title);
      font-weight: var(--font-bolder);

      svg {
        margin-right: var(--espacamento-xxs);
      }

      ${props => props.filtro && `
        justify-content: space-between;
        font-size: var(--text);
        font-weight: var(--font-bold);

          span {
            margin-right: auto;
            margin-left: var(--espacamento-xs);

            font-size: var(--small);
            color: var(--app-text-50-color);
          }
      `}
    }
  }
`;
export const ContainerConteudo = styled(DialogContent)`
  body[isdesktop="true"] && {
    overflow: hidden;
  }
  && {
    padding: 0;
    margin: var(--espacamento-xs) 0;
  }
`;
export const Conteudo = styled.div`
  && {
    font-size: var(--small);
    color: var(--app-text-black-color);
  }
`;
export const ContainerAcoes = styled(DialogActions)`
  && {
    button {
      padding: var(--button-padding);
    }
  }
`;