import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ContextProvider from './contextos/AppContext';
import ContextoEleitoralProvider from './contextos/ContextoEleitoral';
import { getToken } from './servicos/autenticacao';

import { Notificacoes, Eleitoral, NaoAutorizado } from './modulos';

import GlobalStyle from './estilos/GlobalStyle.js';

const token = getToken();

ReactDOM.render(
  <ContextProvider>
    <BrowserRouter>
      {token ?
        <Switch>
          <Route path='/notificacoes' component={Notificacoes} />
          <ContextoEleitoralProvider>
            <Route path='/eleitoral' component={Eleitoral} />
          </ContextoEleitoralProvider>
        </Switch>
        : <NaoAutorizado />
      }
    </BrowserRouter>
    <GlobalStyle />
  </ContextProvider>,
  document.getElementById('root'),
);

// TODO: PWA com ServiceWorker
