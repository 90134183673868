export function getAmbiente() {
  const  hostname = window.location.hostname; // it can set string for test

  if (hostname.match(/localhost/g))
    return 'localhost';
  else if (hostname.match(/-develop/g))
    return 'develop';

  return 'production';
}

export function timeSince(timestamp) {
  var seconds = Math.floor((new Date() - timestamp) / 1000);
  var interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " anos";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " meses";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " dias";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " horas";
  }
  if (interval == 1) {
    return interval + " hora";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutos";
  }
  return "segundos";
};

export const dispositivoIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
