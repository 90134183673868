import axios from 'axios';
import { getToken, getSchema, getOrigin } from './autenticacao';
import { getUrlApi } from './apiUrl';

// const api = axios.create({
//   // baseUrl: getApiUrl(),
//   headers: {
//     'Content-Type': 'application/json',
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Methods': 'DELETE, POST, PUT, GET, OPTIONS',
//     'Access-Control-Max-Age': 86400,
//   },
// });

// api.interceptors.request.use((config) => {
//   config.headers['x-access-token'] = 'd1948f55-9d28-4ddc-bf2a-0f94077fc129';

//   return config;
// })

const token = getToken();
const schema = getSchema();


export const requisicao = async (api, url, metodo, corpo) => {
  try {
    const urlCompleta = getUrlApi(api) + url;
    let headers = {
      'Content-Type': 'application/json',
      'schema': schema,
      'Origin': origin
    };

    if (metodo.toLowerCase() !== 'get')
      headers['app'] = 5;

    if (api === 'api-java' || api === 'api-eleitoral')
      headers['Authorization'] = `Bearer ${token}`;
    else
      headers['x-access-token'] = token;

    const resposta = await axios({
      method: metodo,
      url: urlCompleta,
      data: corpo ? JSON.stringify(corpo) : null,
      headers,
    });
    return resposta;
  } catch (erro) {
    if (erro.resposta?.status >= 400 && erro.resposta?.status <= 500) {
      throw erro.resposta.data;
    } else {
      throw new Error(erro);
    }
  }
};

// api.interceptors.request.use((config) => {
//   // const token = getToken();

//   config.headers['x-access-token'] = 'd1948f55-9d28-4ddc-bf2a-0f94077fc129';

//   return config;
// });

// const requisicao = async (url, metodo, corpo) => {
//   try {
//     const resposta = await api({
//       method: metodo,
//       url: url,
//       data: corpo ? JSON.stringify(corpo) : null,
//     });

//     console.log('api: ', resposta)
//     return resposta.data;
//   } catch (erro) {
//     if (erro.resposta.status >= 400 && erro.resposta.status <= 500) {
//       throw erro.resposta.data;
//     }
//   }
// };

// // Métodos HTTP
// export const get = (url) => api(url, 'get');
// export const post = (url, corpo) => requisicao(url, 'post', corpo);
// export const put = (url, corpo) => requisicao(url, 'put', corpo);
