import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  memo,
  useMemo
} from 'react';
import { GoogleApiWrapper, HeatMap, InfoWindow, Map, Marker } from 'google-maps-react';

import { getUrlApi } from '../../servicos/apiUrl';
import { ContextoEleitoral } from '../../contextos/ContextoEleitoral';

import { gradient, mapStyle } from './MapsOpt';

import { Loading } from '../';

import './style.css';

let posicaoAtual = {};


export const GoogleMaps = (props) => {
  const {
    dadosCandidato,
    exibindoInfoWindow,
    localSelecionado,
    marcadorAtivo,
    marcadoresComites,
    marcadoresLocaisDeVotacao,
    schemaGeral,
    getMarcadoresComites,
    getMarcadoresLocaisDeVotacao,
    onInfoWindowClose,
    onMarkerClick,
    setCarregando,
  } = useContext(ContextoEleitoral);

  let { latitude, longitude } = dadosCandidato;

  const [ marcadoresParaMapaDeCalor, setMarcadoresParaMapaDeCalor ] = useState([]);
  const [ raioDeVisualizacao ] = useState(14);
  const [ habilitaMapaDeCalor, setHabilitaMapaDeCalor ] = useState(false);

  useEffect(() => {
    if(schemaGeral) {
      props.setAbrirFiltro(true);
    }
  }, [schemaGeral]);

  useEffect(() => {
    setHabilitaMapaDeCalor(false);
    if(latitude && longitude && dadosCandidato !== {}) {
      posicaoAtual = {
        latitude: dadosCandidato.latitude,
        longitude: dadosCandidato.longitude
      };
      getMarcadores();
    }
  }, [ getMarcadoresComites, dadosCandidato ]);

  useEffect(() => {
    if (marcadoresLocaisDeVotacao.length > 0) {
      const marcadores = marcadoresLocaisDeVotacao.map(local => ({
        lat: local.latitude,
        lng: local.longitude,
        weight: local.total_votos,
      }));
      setMarcadoresParaMapaDeCalor(marcadores);
    }
  }, [marcadoresLocaisDeVotacao]);

  const getMarcadores = useCallback(() => {
    setCarregando(true);
    getMarcadoresComites(latitude, longitude, raioDeVisualizacao);
    getMarcadoresLocaisDeVotacao(dadosCandidato.cpf);

  }, [latitude, longitude, raioDeVisualizacao, dadosCandidato, setCarregando]);

  const obtemMarcadoresLocaisDeVotacao = useMemo(() => {

    if(habilitaMapaDeCalor) {
      return <HeatMap
        gradient={gradient}
        positions={marcadoresParaMapaDeCalor}
        opacity={0.85}
        radius={25}
      />;
    }

    return marcadoresLocaisDeVotacao && marcadoresLocaisDeVotacao.map((marcador, index) => {
      const iconComVotos = {
        // url: `http://localhost:8000/util/label-votos/${marcador.total_votos}`,
        url: `${getUrlApi('api-comites')}/util/label-votos/${marcador.total_votos}`,
        anchor: new google.maps.Point(18,16),
        scaledSize: new google.maps.Size(36,33)
      };
      const iconSemVotos = {
        // url: `http://localhost:8000/util/label-votos/0`,
        url: `${getUrlApi('api-comites')}/util/label-votos/${marcador.total_votos}`,
        anchor: new google.maps.Point(5,5),
        scaledSize: new google.maps.Size(10,10)
      };

      return <Marker
        key={`local_${index}`}
        name={marcador.local}
        infoContent={<div>
          <strong>{marcador.local}</strong>
          <br/>Votos: {marcador.total_votos} - Seções: {marcador.total_secoes}
        </div>}
        position={{
          lat: marcador.latitude,
          lng: marcador.longitude,
        }}
        icon={marcador.total_votos > 0 ? iconComVotos : iconSemVotos}
        feature={'localDeVotacao'}
        onClick={onMarkerClick}
      />;
    });
  }, [marcadoresLocaisDeVotacao, habilitaMapaDeCalor]);

  const obtemMarcadoresComites = useMemo(() => {
    return marcadoresComites && marcadoresComites.map((marcador, index) => (
      <Marker
        key={`comite_${index}`}
        name={marcador.nome}
        infoContent={<div>
          <strong>Comitê {marcador.bairro}</strong>
          <br/>{marcador.nome}
          <a
            target="_parent"
            href={`${document.referrer.replace('/eleitoral', '')}comite-pessoas/${marcador.idcomite}`}
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontFamily: 'var(--font-2)',
              padding: 'var(--espacamento-xs)',
              paddingBottom: 0,
              color: 'var(--button-font-color)',
              fontWeight: '500',
              textDecoration: 'none',
              textTransform: 'uppercase'
            }}
          >
            Abrir Comitê
          </a>
        </div>}
        position={{
          lat: marcador.latitude,
          lng: marcador.longitude,
        }}
        feature={'comite'}
        icon={{
          url: 'https://voluntario.infogab.com.br/images/icon-comite-familiar-orig.svg',
          anchor: new google.maps.Point(16,40),
          scaledSize: new google.maps.Size(32,40)
        }}
        onClick={onMarkerClick}
      />
    ));
  }, [marcadoresComites]);

  const _mapLoaded = useCallback((mapProps, map) => {
    map.setOptions({
      styles: mapStyle
    });
  }, []);

  const quandoOcorreMudanca = useCallback((antigoValor, novoValor) => {
    posicaoAtual = {
      latitude: novoValor.center.lat(),
      longitude: novoValor.center.lng()
    };
  }, []);

  // Remove rolagem
  document.querySelector('body').style.overflow = 'hidden';

  // Output
  if(latitude && longitude) {
    return (
      <>
        <Map
          style={{
            position: 'relative',
            zIndex: '1',
            display: 'block',
            height: 'calc(100% - 88px)',
            overflow: 'hidden',
          }}
          google={props.google}
          streetViewControl={false}
          mapTypeControl={false}
          fullscreenControl={false}
          zoom={12}
          minZoom={10}
          maxZoom={18}
          gestureHandling='greedy'
          initialCenter={{
            lat: latitude,
            lng: longitude
          }}
          center={{
            lat: posicaoAtual.latitude || latitude,
            lng: posicaoAtual.longitude || longitude
          }}
          onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
          onClick={onInfoWindowClose}
          onBoundsChanged={quandoOcorreMudanca}
        >

          {marcadoresComites.length && obtemMarcadoresComites}

          {marcadoresLocaisDeVotacao.length && obtemMarcadoresLocaisDeVotacao}

          <InfoWindow
            marker={marcadorAtivo}
            onClose={onInfoWindowClose}
            visible={exibindoInfoWindow}>
            <div>{localSelecionado.infoContent}</div>
          </InfoWindow>
        </Map>

        <button
          className="btnHabilitaMapaDeCalor"
          onClick={() => setHabilitaMapaDeCalor(!habilitaMapaDeCalor)}>
          Alterar modo
        </button>
      </>
    );
  }
  else {
    return <Loading full/>;
  }
};

export default memo(GoogleApiWrapper(
  (props) => (
    {
      apiKey: 'AIzaSyAktK0FGb26v0uL2NyFNUHEE_vG3YOfCE4',
      libraries: ['visualization'],
      LoadingContainer: 'div'
    }
  )
)(memo(GoogleMaps)));
