import React, { useCallback, useContext } from 'react';

import { Context } from '../../contextos/AppContext';

import { MensagemDeErro } from '../../componentes';
import { Botao } from '../../componentes/Interfaces';

import { timeSince } from '../../util/util';

import {
  Container,
  Cabecalho,
  CheckBoxContainer,
  Titulo,
  NovaNotificacao,
  TempoDeCriacao,
  Conteudo,
  Rodape,
  CheckBox,
  SemNotificacoes,
  Carregando
} from './styles';

let notificacaoAtual;

const Notificacao = () => {
  const {
    alteraNotificacaoLida,
    notificacoes,
    referenciaDaUltimaNotificacao,
    atualizandoNotificacoes
  } = useContext(Context);

  const handleChange = useCallback((notificacao) => {
    notificacao.lido = !Boolean(notificacao.lido);
    notificacaoAtual = notificacao.idnotificacao;
    alteraNotificacaoLida(notificacao);
  }, []);

  return (
    <>
      {notificacoes.length
        ? notificacoes.map((notificacao, index) => (
          <Container ref={notificacoes.length === index + 1 ? referenciaDaUltimaNotificacao : null} key={index} lido={notificacao.lido}>
            <Cabecalho>
              <div>
                {!notificacao.lido && <NovaNotificacao />}
                <Titulo>{notificacao.notificacao}</Titulo>
              </div>
              <TempoDeCriacao>{timeSince(new Date(notificacao.dtcadastro).getTime())} atrás</TempoDeCriacao>
            </Cabecalho>
            <Conteudo>{notificacao.observacao}</Conteudo>
            <Rodape>
              <Botao target="_parent" href={`${document.referrer.replace('/notificacoes', '')}pessoa-detalhes/${notificacao.idpessoa}`}>Abrir Cadastro</Botao>
              <CheckBoxContainer
                control={atualizandoNotificacoes && notificacaoAtual === notificacao.idnotificacao
                  ? <Carregando />
                  : <CheckBox checked={notificacao.lido} onChange={() => handleChange(notificacao)} />
                }
                label="Lido"
              />
            </Rodape>
          </Container>
        ))
        : <SemNotificacoes>Nenhum Registro Encontrado</SemNotificacoes>}
      <MensagemDeErro />
    </>
  );
};

export default Notificacao;
