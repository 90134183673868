import styled from 'styled-components';

import { AppBar, Typography, Toolbar, IconButton } from '@material-ui/core';

export const Component = styled(AppBar)`
  && {
    background-color: var(--app-background-white-color);
    color: var(--app-text-black-color);
  }
`;

export const Container = styled(Toolbar)`
  && {
    min-height: auto;
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: var(--fonte-1);
    font-size: var(--subtitle);
    flex-grow: 1;
  }
`;

export const IconeDireitaContainer = styled(IconButton)`
  position: relative;
`;

export const Filtrado = styled.span`
  position: absolute;
  top: 12px;
  right: 12px;

  height: 10px;
  width: 10px;

  background-color: red;
  border-radius: 50%;
`;

export const SubContainer = styled(Toolbar)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;

    min-height: 48px;

    background-color: var(--app-background-10-color);
  }
`;