import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Component, Container, SubContainer, Title, IconeDireitaContainer, Filtrado } from './styles';
import Loading from '../Loading';


const TopBar = ({
  carregando,
  acaoIconeDireita,
  filtro,
  iconeDireita,
  iconeEsquerda = <ArrowBackIcon/>,
  position = 'fixed',
  titulo,
  subCabecalho,
}) => {

  const history = useHistory();

  return (
    <Component position={position}>
      <Container>
        <IconButton
          onClick={() => history.goBack(-2)}
          edge="start">
          {iconeEsquerda}
        </IconButton>
        <Title variant="h6">
          {titulo}
        </Title>
        {carregando && <Loading topbar/>}
        {iconeDireita &&
          <IconeDireitaContainer
            onClick={() => acaoIconeDireita && acaoIconeDireita()}
            edge="end">
            {iconeDireita}
            {filtro && <Filtrado />}
          </IconeDireitaContainer>
        }
      </Container>
      {subCabecalho &&
        <SubContainer>
          {subCabecalho}
        </SubContainer>
      }
    </Component>
  );
};

export default memo(TopBar);