import React, { memo } from 'react';

import { Botao } from '../../componentes/Interfaces/';

import {
  Container,
  Titulo,
  ContainerConteudo,
  Conteudo,
  ContainerAcoes,
} from './style';

const MuiDialog = ({
  abrir,
  acaoBotaoPrimario,
  acaoBotaoSecundario,
  botaoPrimario,
  botaoSecundario,
  conteudo,
  desabilitar,
  filtro,
  titulo,
  tituloIcone,
}) => {

  return (
    <>
      <Container
        open={abrir}
        filtro={filtro}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Titulo id="alert-dialog-title" filtro={filtro}>
          {tituloIcone} {titulo}
        </Titulo>
        <ContainerConteudo>
          <Conteudo id="alert-dialog-description">
            {conteudo}
          </Conteudo>
        </ContainerConteudo>
        {botaoPrimario &&
          <ContainerAcoes>
            {botaoSecundario &&
              <Botao 
                secondary="true"
                disabled={false} 
                onClick={acaoBotaoSecundario}
              >
                {botaoSecundario}
              </Botao>
            }
            <Botao
              primary="true"
              disabled={desabilitar}
              onClick={acaoBotaoPrimario}
            >
              {botaoPrimario}
            </Botao>
          </ContainerAcoes>
        }
      </Container>
    </>
  );
};

export default memo(MuiDialog);