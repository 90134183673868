import React, { useContext, memo, useEffect, useState } from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import { ContextoEleitoral } from '../../../contextos/ContextoEleitoral';

import { MuiFormControl } from './styles';

const AnoEleicao = ({ setDesabilitaBotao }) => {
  const { anoEleitoralSelecionado, setAnoEleitoralSelecionado, anosEleitorais } = useContext(ContextoEleitoral);
  const [anoAtual, setAnoAtual] = useState(anoEleitoralSelecionado || '');

  useEffect(() => {
    if(anoEleitoralSelecionado)
      setDesabilitaBotao(false);

    setAnoEleitoralSelecionado(anoAtual);
  }, [anoEleitoralSelecionado, anoAtual]);

  const alteraAno = (e) => {
    setAnoAtual(e.target.value);
    setDesabilitaBotao(false);
  };

  return (
    <MuiFormControl>
      <InputLabel id="ano-eleitoral-label">Selecione o ano</InputLabel>
      <Select
        labelId="ano-eleitoral-label"
        id="ano-eleitoral"
        
        autoWidth

        value={anoAtual}
        onChange={alteraAno}
      >
        {anosEleitorais && anosEleitorais.map((anoEleitoral, index) => (
          <MenuItem key={index} value={anoEleitoral}>{anoEleitoral}</MenuItem>
        ))}
      </Select>
    </MuiFormControl>
  );
};

export default memo(AnoEleicao);