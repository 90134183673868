import React, { memo } from 'react';
import styled from 'styled-components';

import HowToVoteIcon from '@material-ui/icons/HowToVote';
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

export const Container = styled.span`
  max-width: 40%;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  color: var(--app-text-color);

  svg {
    opacity: 0.4;
    margin-right: var(--espacamento-xxs);
    vertical-align: bottom;
  }

  &:nth-child(1),
  &:nth-child(4) {
    min-width: 60px;
  }
`;


const InfoCandidato = ({ candidato, carregando }) => {
  return (
    <>
      {carregando
        ? <Container>Carregando...</Container>
        : <>
          <Container>
            <CalendarTodayIcon fontSize="small" />
            {candidato.ano}
          </Container>
          <Container>
            <RoomIcon fontSize="small"/>
            {candidato.cidade}
          </Container>
          <Container>
            <PersonIcon fontSize="small"/>
            {candidato.nome_urna || candidato.nome}
          </Container>
          {candidato.total_votos &&
            <Container>
              <HowToVoteIcon fontSize="small"/>
              {candidato.total_votos.toLocaleString('pt-br')}
            </Container>
          }
        </>
      }
    </>
  );
};

export default memo(InfoCandidato);