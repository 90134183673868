import React, { useCallback, useEffect, useContext, useState, memo } from 'react';

import { ContextoFiltro } from '../../../contextos/ContextoFiltro';
import { ContextoEleitoral } from '../../../contextos/ContextoEleitoral';

import { MuiInputAutocomplete } from '../../';

const Cidade = ({ deputado, schemaGeral, candidatoAtual, setCandidatoAtual, setDesabilitaBotao }) => {
  const { dadosCandidato } = useContext(ContextoEleitoral);
  const { getCidades, cidades, setCidades, carregando } = useContext(ContextoFiltro);

  useEffect(() => {
    setDesabilitaBotao(true);
    setCidades([]);
    setCandidatoAtual(dadosCandidato);

    if(dadosCandidato.cidade || (!deputado && !schemaGeral)) {
      setDesabilitaBotao(false);
    }
  }, []);

  const altera = useCallback((event, value, reason) => {
    if(reason === 'select-option') setDesabilitaBotao(false);

    if(candidatoAtual.idCidade === value.idcidade) return;

    setCandidatoAtual({
      ...candidatoAtual,
      latitude: value.latitude,
      longitude: value.longitude,
      cidade: value.cidade,
      idCidade: value.idcidade
    });
  }, [candidatoAtual, setCandidatoAtual]);

  return (
    <MuiInputAutocomplete
      valorInicial={candidatoAtual.cidade}
      label='Cidade'
      opcoes={cidades}
      opcoesLabel={option => option.cidade}
      alteraValores={altera}
      setDesabilita={setDesabilitaBotao}
      requisicao={getCidades}
      carregando={carregando}
      disabled={(!deputado && !schemaGeral) && true}
    />
  );
};

export default memo(Cidade);