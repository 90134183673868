import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const Container = styled.div`
  && {
    display: flex;
    justify-content: center;

    padding: ${props => props.full ? '20vh;' : '5vh;'};
    box-sizing: border-box;

  ${props => props.full && `
    width: 100vw;
    height: 100vh;
  `}

  max-width: 600px;

  margin: 0 auto;

  ${props => props.topbar &&
  `
    padding: 0;
    margin-right: var(--espacamento-s);
  `}

  ${props => props.input && `
    position: absolute;
    right: 6px;
    bottom: 8px;

    padding: 0;
    margin: 0;
  `}

    .MuiCircularProgress-colorPrimary {
      ${props => props.full && `
        margin-top: 50px;
      `}
      color: var(--app-primary-color);
    }
  }

  body {
    overflow-y: hidden;
  }
`;

const Loading = ({ full, topbar, input }) => {
  return (
    <Container
      full={full}
      topbar={topbar}
      input={input}
    >
      <CircularProgress size={topbar || input ? 25 : 40}/>
    </Container>
  );
};

export default Loading;