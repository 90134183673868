import React, { useState, useEffect, memo } from  'react';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import Loading from '../Loading';


const MuiInputAutocomplete = ({ 
  valorInicial, 
  opcoes, 
  label, 
  opcoesLabel, 
  requisicao, 
  alteraValores, 
  carregando, 
  setDesabilita,
  disabled = false,
}) => {
  const [ inputDigitado, setInputDigitado ] = useState(valorInicial);

  useEffect(() => { setInputDigitado(valorInicial); },[valorInicial]);

  return (
    <Autocomplete
      freeSolo
      clearOnEscape
      disableClearable
      disabled={disabled}
      options={opcoes}
      getOptionLabel={opcoesLabel}
      onChange={alteraValores}
      onInputChange={(event, value, reason) => {
        if(setDesabilita) {
          if(reason === 'input') setDesabilita(true);
          else setDesabilita(false);
        }

        setInputDigitado(value);
        requisicao(value);
      }}
      style={{ position:'relative' }}
      renderInput={params =>
        <>
          <TextField
            {...params}
            fullWidth
            label={label}
            autoComplete='off'
            inputProps={{ ...params.inputProps, value: (inputDigitado || '') }}
          />
          {carregando && <Loading input/>}
        </>
      }
    />
  );
};

export default memo(MuiInputAutocomplete);