import React, {
  useContext,
  useEffect,
  useCallback,
  memo
} from 'react';

import { Context } from '../../contextos/AppContext';

import { TopBar, Notificacao, Loading } from '../../componentes';
import { Container } from '../../componentes/Interfaces';
import RefreshIcon from '@material-ui/icons/Refresh';


const Notificacoes = () => {
  const {
    carregando,
    getNotificacoes,
    carregandoNotificacoes,
    recarregaNotificacoes,
  } = useContext(Context);

  useEffect(() => {
    getNotificacoes();
  }, [getNotificacoes]);

  const refreshPage = useCallback(() => {
    recarregaNotificacoes();
  }, []);

  return (
    <>
      <TopBar
        titulo='Notificações'
        iconeDireita={<RefreshIcon/>}
        acaoIconeDireita={refreshPage}
      />
      <Container bgGray wrapped>
        {carregando
          ? <Loading full />
          : <Notificacao />
        }
        {carregandoNotificacoes && <Loading />}
      </Container>
    </>
  );
};

export default memo(Notificacoes);
