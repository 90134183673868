import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Titulo = styled(Typography)`
 && {
  font-size: var(--title);
  color: var(--app-text-color);

  margin-bottom: var(--espacamento-s);
 }
`;

export const Texto = styled(Typography)`
 && {
   font-size: var(--text);
   color: var(--app-text-color);

   text-align: center;
 }
`;

export const Icone = styled(BlockIcon)`
  && {
    font-size: var(--title-bigger);
    color: var(--app-text-color);

    margin-bottom: var(--espacamento-m);
  }
`;