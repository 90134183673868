import { getAmbiente } from "../util/util";

const API = {
  'api-notificacoes': {
    localhost: 'https://api-notificacoes-develop.rj.r.appspot.com',
    develop: 'https://api-notificacoes-develop.rj.r.appspot.com',
    production: 'https://api-notificacoes-production.rj.r.appspot.com',
  },
  'api-java': {
    localhost: 'https://sistema-develop.infogab.com.br/api-v2',
    develop: 'https://sistema-develop.infogab.com.br/api-v2',
    production: 'https://sistema.infogab.com.br/api-v2',
  },
  'api-eleitoral': {
    localhost: 'https://api-bi-develop.uc.r.appspot.com',
    develop: 'https://api-bi-develop.uc.r.appspot.com',
    production: 'https://api-bi-production.uc.r.appspot.com',
  },
  'api-pessoas': {
    localhost: 'https://api-pessoas-develop.rj.r.appspot.com',
    develop: 'https://api-pessoas-develop.rj.r.appspot.com',
    production: 'https://api-pessoas-production.rj.r.appspot.com',
  },
  'api-comites': {
    localhost: 'https://api-comites-develop.rj.r.appspot.com',
    develop: 'https://api-comites-develop.rj.r.appspot.com',
    production: 'https://api-comites-production.rj.r.appspot.com',
  },
  'api-enderecos': {
    localhost: 'https://api-enderecos-develop.rj.r.appspot.com',
    develop: 'https://api-enderecos-develop.rj.r.appspot.com',
    production: 'https://api-enderecos-production.rj.r.appspot.com',
  },
};

export const getUrlApi = (api) => {
  const ambiente = getAmbiente();
  return API[api][ambiente];
};