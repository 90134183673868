import React, { useCallback, useState, useMemo, useContext, useEffect, memo } from 'react';
import _ from 'lodash';
import { List } from 'react-virtualized';

import { ContextoFiltro } from '../../../contextos/ContextoFiltro';
import { ContextoEleitoral } from '../../../contextos/ContextoEleitoral';

import { TextField } from '@material-ui/core';

import { FiltroContainer, Titulo, ConteudoLista, ConteudoItem, Label, RadioButton, ListaVazia } from './styles';
import Loading from '../../Loading';


const Candidato = ({ etapa, candidatoAtual, setCandidatoAtual, setDesabilitaBotao }) => {
  const { getCandidatos, candidatos, carregando } = useContext(ContextoFiltro);
  const { anoEleitoralSelecionado } = useContext(ContextoEleitoral);
  const [ candidatosFiltrado, setCandidatosFiltrado ] = useState(candidatos);
  const [ candidatosProjeto, setCandidatosProjeto ] = useState([]);
  const [ selecionado, setSelecionado ] = useState(candidatoAtual.nome);
  const [ exibeCabecalho, setExibeCabecalho] = useState(true);

  useEffect(() => {
    if(candidatoAtual.nome)
      setDesabilitaBotao(false);
    else 
      setDesabilitaBotao(true);
  }, []);

  useEffect(() => {
    getCandidatos(candidatoAtual.idCidade, anoEleitoralSelecionado);
  }, [etapa]);

  useEffect(() => {
    setCandidatosFiltrado(candidatos);

    setCandidatosProjeto(candidatos.filter((candidato, index) => { if(candidato.projeto.length) return candidato; }));
  }, [candidatos]);

  const filtrarCandidatos = useCallback(valor => {
    let input = valor.target.value;
    let filtro = candidatos;
    filtro = filtro.filter(candidato => candidato.nome_urna.toLowerCase().includes(input.toLowerCase()));
    if (input.length) {
      setExibeCabecalho(false);
    } else {
      setExibeCabecalho(true);
    }
    setCandidatosFiltrado(filtro);
  }, [candidatos]);

  const alteraRadioButton = useCallback((e) => {

    setSelecionado(e.target.value);
    const candidatoSelecionado = candidatos.filter(candidato => candidato.nome === e.target.value)[0];

    setDesabilitaBotao(false);

    setCandidatoAtual({
      ...candidatoAtual,
      cpf: candidatoSelecionado.cpf,
      nome: candidatoSelecionado.nome,
      nome_urna: candidatoSelecionado.nome_urna,
      total_votos: candidatoSelecionado.total_votos
    });
  }, [candidatos]);

  const tamanhoLinhaCandidatos = useMemo(() => {
    const tamanho = candidatosProjeto.length * 50;
    if (tamanho >= 200) return 200;
    else return tamanho;
  });
  return (
    <>
      <TextField
        fullWidth
        label='Procurar por...'
        onChange={filtrarCandidatos}
        autoComplete="off"
      />
      <FiltroContainer>
        {carregando
          ? <Loading />
          : <>
            {(exibeCabecalho) &&
              <>
                <Titulo>Projeto</Titulo>
                <ConteudoLista>
                  {candidatosProjeto.length?
                    <List
                      rowCount={candidatosProjeto.length}
                      width={800}
                      height={tamanhoLinhaCandidatos}
                      rowHeight={50}
                      autoWidth={true}
                      rowRenderer={ ({ index, isScrolling, key, style }) => {
                        if(candidatosProjeto[index].projeto.length) {
                          return (
                            <ConteudoItem
                              key={index}
                              style={style}
                              checked={candidatosProjeto[index].nome === selecionado}
                              eleito={candidatosProjeto[index].eleito.toString()}
                              control={
                                <RadioButton
                                  name={candidatosProjeto[index].nome}
                                  value={candidatosProjeto[index].nome}
                                  checked={candidatosProjeto[index].nome === selecionado}
                                  onChange={e => alteraRadioButton(e)}
                                />
                              }
                              label={
                                <Label>
                                  <span>{candidatosProjeto[index].eleito && 'Eleito'}</span>
                                  <p>{candidatosProjeto[index].nome_urna}</p>
                                  <span>{candidatosProjeto[index].total_votos}</span>
                                </Label>
                              }
                            />
                          );
                        }
                      }}
                      overscanRowCount={3}
                    />
                    :
                    <ListaVazia>
                      <p>Nenhum Candidato Encontrado</p>
                    </ListaVazia>}
                </ConteudoLista>
              </>
            }
            {(exibeCabecalho) && <Titulo style={{ marginTop: 'var(--espacamento-m)' }}> Todos </Titulo>}
            <ConteudoLista>
              {candidatosFiltrado.length ?
                <List
                  rowCount={candidatosFiltrado.length}
                  width={800}
                  height={250}
                  rowHeight={50}
                  autoWidth={true}
                  rowRenderer={ ({ index, isScrolling, key, style }) => {
                    return (
                      <ConteudoItem
                        key={index}
                        style={style}
                        checked={candidatosFiltrado[index].nome === selecionado}
                        eleito={candidatosFiltrado[index].eleito.toString()}
                        control={
                          <RadioButton
                            name={candidatosFiltrado[index].nome}
                            value={candidatosFiltrado[index].nome}
                            checked={candidatosFiltrado[index].nome === selecionado}
                            onChange={e => alteraRadioButton(e)}
                          />
                        }
                        label={
                          <Label>
                            <span>{candidatosFiltrado[index].eleito && 'Eleito'}</span>
                            <p>{candidatosFiltrado[index].nome_urna}</p>
                            <span>{candidatosFiltrado[index].total_votos}</span>
                          </Label>
                        }
                      />
                    );
                  }}
                  overscanRowCount={3}
                />
                :
                <ListaVazia>
                  <p>Nenhum Candidato Encontrado</p>
                </ListaVazia>}
            </ConteudoLista>
          </>
        }
      </FiltroContainer>
    </>
  );
};

export default memo(Candidato);