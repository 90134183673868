import React, { useState, useContext, createContext, useCallback } from 'react';
import _ from 'lodash';

import { requisicao } from '../servicos/api';

import { ContextoEleitoral } from '../contextos/ContextoEleitoral';

export const ContextoFiltro = createContext();

let tempo;
let ultimoIdCidade, ultimoAnoEleitoralSelecionado;

const ContextoFiltroProvider = props => {

  const { dadosCandidato, setDadosCandidato } = useContext(ContextoEleitoral);

  const [ carregando, setCarregando ] = useState(false);
  const [ cidades, setCidades ] = useState([]);
  const [ candidatos, setCandidatos ] = useState([]);
  const [ candidatoAtual, setCandidatoAtual ] = useState({});

  const getCidades = useCallback(async cidade => {
    setCarregando(true);
    clearTimeout(tempo);

    tempo = setTimeout(async () => {
      const url = `/cidades?uf=SP&nome=${cidade}&distritos=false`;
      const resposta = await requisicao('api-enderecos', url, 'GET');

      if(resposta.status === 200) {
        setCidades(resposta.data);
        setCarregando(false);
      }
    }, 600);

  }, [cidades, setCidades]);

  const getCandidatos = useCallback(async (idCidade, anoEleitoralSelecionado) => {
    if (idCidade === undefined || (ultimoAnoEleitoralSelecionado === anoEleitoralSelecionado && ultimoIdCidade === idCidade))
      return;

    setCarregando(true);

    try {
      ultimoIdCidade = idCidade;
      ultimoAnoEleitoralSelecionado = anoEleitoralSelecionado;

      const url = `/votacao/candidatos/${anoEleitoralSelecionado}/?cidade=${idCidade}`;
      const resposta = await requisicao('api-eleitoral', url, 'GET');

      if(resposta.status === 200) {
        const data = resposta.data.map(item => {
          const eleito = item.situacao.includes('ELEITO') && !item.situacao.includes('NÃO');
          if(item.cpf === dadosCandidato.cpf) {
            setDadosCandidato({
              ...dadosCandidato,
              ...item,
              nome: _.startCase(_.toLower(item.nome)),
              nome_urna: _.startCase(_.toLower(item.nome_urna)),
            });
          }
          return {
            ...item,
            nome: _.startCase(_.toLower(item.nome)),
            nome_urna: _.startCase(_.toLower(item.nome_urna)),
            eleito: eleito
          };
        });
        setCandidatos(_.orderBy(data, ['eleito', 'total_votos'], ['desc', 'desc']));
      }
    } catch (e) {
      setCandidatos([]);
      console.error(e);
    } finally {
      setCarregando(false);
    }

  }, [candidatos, dadosCandidato, setCandidatos, setDadosCandidato]);

  return (
    <ContextoFiltro.Provider value={{
      candidatos,
      candidatoAtual,
      carregando,
      cidades,
      getCandidatos,
      getCidades,
      setCandidatoAtual,
      setCidades,
    }}>
      {props.children}
    </ContextoFiltro.Provider>
  );
};

export default ContextoFiltroProvider;
