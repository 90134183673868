import { createGlobalStyle } from 'styled-components';
import { getAmbiente } from '../util/util';

const GlobalStyle = createGlobalStyle`
  //Reset
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: var(--fonte-1);
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ml-auto {
    margin-left: auto;
  }

  :root {
    --espacamento-xxs: 4px;
    --espacamento-xs: 8px;
    --espacamento-s: 16px;
    --espacamento-m: 24px;
    --espacamento-g: 32px;
    --espacamento-xg: 40px;

    --fonte-1: 'Roboto Condensed', 'Noto', sans-serif;
    --fonte-2: 'Roboto', 'Noto', sans-serif;

    --font-thin: 300;
    --font-normal: 400;
    --font-bold: 500;
    --font-bolder: 600;

    --title-bigger: 1.75rem;
    --title-big: 1.5rem;
    --title: 1.35rem;
    --subtitle: 1.15rem;
    --text: 1rem;
    --small: 0.85rem;

    ${getAmbiente() === 'production' ? '--app-primary-color: #0B285B;' : '--app-primary-color: #00c05f;'}
    --app-secondary-color: #1b8030;
    --app-background-color: #0B285B;
    --app-background-white-color: #FFFFFF;
    --app-background-10-color: rgba(46,46,48,0.1);
    --app-background-20-color: rgba(46,46,48,0.2);
    --app-background-50-color: rgba(46,46,48,0.5);
    --app-text-black-color: #000000;
    --app-text-color: #414141;
    --app-text-50-color: rgba(30,30,30,0.5);
    --app-icon-color: #808080;
    --app-error-color: #D53B3B;

    --filtro-selecionado: #8AE4B3;

    --button-font-color: #2962ff;
    --button-padding: var(--espacamento-xs) var(--espacamento-s);
  }
`;

export default GlobalStyle;
